a:link,
a:active,
a:visited,
a:focus,
a:hover {
  color: inherit;
}

/* chrome likes to a add a dotted underline to these */
abbr[title] {
  text-decoration: none;
}
