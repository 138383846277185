@font-face {
  font-family: "Gilroy";
  src: url("../bundle-data/fonts/Radomir Tinkov - Gilroy-Medium.otf")
    format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url("../bundle-data/fonts/Radomir Tinkov - Gilroy-SemiBold.otf")
    format("opentype");
  font-weight: 600;
}
